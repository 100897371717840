<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { useQuery } from '@tanstack/vue-query';
import HyptTLogo from '~/assets/svg/hyptt.svg';
import type * as icons from '@/assets/icons';

const { isAgencySpace, isSupportSpace } = storeToRefs(useAgencyStore());

const isCollasped = ref(false);
const breakpoints = useBreakpoints(breakpointsTailwind);
const mdAndSmaller = breakpoints.smallerOrEqual('md');

const { currentClient } = storeToRefs(useClientStore());
const route = useRoute();
const clientSlug = computed<string>(() => {
  if (isAgencySpace.value) return 'agency';
  else if (isSupportSpace.value) return 'support';
  else if (currentClient.value) return currentClient.value.slug;
  else if (route.params.clientSlug) return route.params.clientSlug as string;
  else return '';
});

const isSettings = ref(false);
const isSettingsActive = computed(() => {
  if (isAgencySpace.value) return false;
  if (isSettings.value) return true;
  return route.fullPath.includes('/settings/');
});

const user = useUser();

const supabase = useSupabaseClient();
const signOut = async () => {
  await supabase.auth
    .signOut()
    .then(() => {
      useClientStore().reset();
      useModuleStore().reset();
      useUserStore().reset();
      navigateTo('/login');
    })
    .catch((error) => {
      console.error('Error logging out:', error.message);
    });
};

const agencyNavs = computed<{ name: string; path: string }[]>(() => {
  if (!user.value) return [];
  // TODO: uncomment when agency overview is ready
  // const navs = [{ name: 'Overview', path: '/agency/overview' }];
  const navs = [];
  if (useAccess(user.value, 'AG_CLIENTS', 'V'))
    navs.push({
      name: 'Clients',
      path: '/agency/clients',
    });
  if (useAccess(user.value, 'AG_MEMBERS', 'V'))
    navs.push({ name: 'Members', path: '/agency/members' });
  if (useAccess(user.value, 'AG_TAGS', 'V'))
    navs.push({ name: 'Tags', path: '/agency/tags' });
  if (useAccess(user.value, 'AG_BUDGETS', 'V'))
    navs.push({
      name: 'Budget Labels',
      path: '/agency/budget-labels',
    });
  if (useAccess(user.value, 'AG_SETTINGS', 'V'))
    navs.push({ name: 'Settings', path: '/agency/settings' });
  return navs;
});

type ClientNav = {
  name: string;
  iconLeft: keyof typeof icons;
  iconLeftColor: string;
  indicator: string;
  notifications: number;
  isActive: boolean;
  onClick: () => void;
};

const fetchClientNotifs = () => api(`/api/notifications/clients`);
const { data: clientNotifications } = useQuery({
  queryKey: ['client-notifications'],
  queryFn: fetchClientNotifs,
  staleTime: 1000 * 60 * 5,
});

const clientNavs = computed<{
  root: ClientNav[];
  settings: { path: string; label: string }[];
}>(() => {
  if (!user.value) return { root: [], settings: [] };
  // register colors with hidden divs, so tailwind generates the classes!

  const rootNavs: ClientNav[] = [];
  // if (useAccess(user.value, 'CL_GENERAL', 'V', clientSlug.value))
  //   rootNavs.push({
  //     name: 'Overview',
  //     iconLeft: 'ReportColumns',
  //     iconLeftColor: '#F0BF29',
  //     indicator: '',
  //     isActive: route.path.startsWith(`/client/${clientSlug.value}/overview`),
  //     isCollasped: isCollasped.value,
  //     onClick: () => navigateTo(`/client/${clientSlug.value}/overview`),
  //   });
  if (useAccess(user.value, 'CL_LIST_PROJECTS', 'L', clientSlug.value))
    rootNavs.push({
      name: 'Projects',
      iconLeft: 'MagicWand',
      iconLeftColor: '#F0BF29',
      indicator:
        isCollasped.value ||
        !useAccess(user.value, 'CL_LIST_PROJECTS', 'V', clientSlug.value)
          ? ''
          : (currentClient.value?._count.projects.toString() ?? '0'),
      notifications:
        clientNotifications.value?.[clientSlug.value]?.projects ?? 0,
      isActive: route.path.startsWith(`/client/${clientSlug.value}/project`),
      onClick: () => navigateTo(`/client/${clientSlug.value}/projects`),
    });
  if (useAccess(user.value, 'CL_LIST_CREATIVES', 'L', clientSlug.value))
    rootNavs.push({
      name: 'Creatives',
      iconLeft: 'ThreeDSelectPoint',
      iconLeftColor: '#CC4D4B',
      indicator:
        isCollasped.value ||
        !useAccess(user.value, 'CL_LIST_CREATIVES', 'V', clientSlug.value)
          ? ''
          : (currentClient.value?._count.creatives.toString() ?? '0'),
      notifications:
        clientNotifications.value?.[clientSlug.value]?.creatives ?? 0,
      isActive: route.path.startsWith(`/client/${clientSlug.value}/creative`),
      onClick: () => navigateTo(`/client/${clientSlug.value}/creatives`),
    });

  // rootNavs.push({
  //   name: 'Reports',
  //   iconLeft: 'StatsReport',
  //   iconLeftColor: '#904FE0',
  //   indicator: '',
  //   isActive: route.path.startsWith(`/client/${clientSlug.value}/report`),
  //   onClick: () => navigateTo(`/client/${clientSlug.value}/reports`),
  // });

  const settingNavs: { path: string; label: string }[] = [];
  if (useAccess(user.value, 'CL_GENERAL', 'V', clientSlug.value))
    settingNavs.push({
      path: `/client/${clientSlug.value}/settings/general`,
      label: 'General',
    });

  if (
    useAccess(user.value, 'CL_TEAM', 'V', clientSlug.value) ||
    useAccess(user.value, 'CL_GUESTS', 'V', clientSlug.value)
  )
    settingNavs.push({
      path: `/client/${clientSlug.value}/settings/collaborators`,
      label: 'Collaborators',
    });

  if (useAccess(user.value, 'AG_TAGS', 'V', clientSlug.value))
    settingNavs.push({
      path: `/client/${clientSlug.value}/settings/tags`,
      label: 'Tags',
    });

  return {
    root: rootNavs,
    settings: settingNavs,
  };
});

const canAccessAgency = computed<boolean>(() => {
  if (!user.value) return false;
  return user.value.agencyId !== null;
});
</script>

<template>
  <div
    :class="{
      'primary-sidebar': true,
      collasped: isCollasped,
      'pt-0': isAgencySpace || isSupportSpace,
    }"
  >
    <!-- Top Section -->
    <div class="flex flex-col gap-4">
      <div
        v-if="isAgencySpace || isSupportSpace"
        class="h-[58px] pl-6 py-4 mb-2 cursor-pointer transition-all duration-300 ease-hub-ease border-b border-ht-border-1 justify-start items-center gap-2.5 inline-flex hover:bg-ht-menu-btn-hover"
        @click="
          navigateTo(
            isSupportSpace
              ? '/agency'
              : currentClient
                ? `/client/${currentClient.slug}`
                : '/'
          )
        "
      >
        <HIcon icon="ArrowLeft" class="h-3.5 w-3.5" />

        <div class="w-full inline-flex items-center justify-between">
          <div class="inline-flex gap-3 items-center">
            <img
              v-if="user?.agency?.logoUrl"
              :src="imageAssetSrc(user?.agency?.logoUrl)"
              class="cc-thumb object-cover object-center"
            />
            <div v-else class="cc-thumb">
              {{
                user?.agency?.name
                  .split(' ')
                  .slice(0, 2)
                  .map((a: string) => (a.length > 0 ? a[0] : ''))
                  .join('')
              }}
            </div>
            <span class="whitespace-nowrap truncate text-sm">{{
              user?.agency?.name
            }}</span>
          </div>
        </div>
      </div>

      <div
        v-else
        :class="{
          'w-full pt-1 pb-3 gap-4 flex flex-col': true,
          'w-auto justify-center flex-col gap-4': isCollasped,
        }"
      >
        <NuxtLink to="/" class="px-5"
          ><HyptTLogo class="fill-ht-hub-text-1 w-[64px]"
        /></NuxtLink>

        <div
          class="w-full px-2 flex-col justify-start items-start gap-1 inline-flex"
        >
          <HButton
            v-if="canAccessAgency"
            variant="plain"
            class="w-full pl-3 pr-2 h-fit rounded-xl hover:bg-ht-menu-btn-hover"
            @click="navigateTo('/agency/clients')"
          >
            <div class="w-full inline-flex items-center justify-between">
              <div class="inline-flex gap-3 items-center">
                <img
                  v-if="user?.agency?.logoUrl"
                  :src="imageAssetSrc(user?.agency?.logoUrl)"
                  class="cc-thumb object-cover object-center"
                />
                <div v-else class="cc-thumb">
                  {{
                    user?.agency?.name
                      .split(' ')
                      .slice(0, 2)
                      .map((a: string) => (a.length > 0 ? a[0] : ''))
                      .join('')
                  }}
                </div>
                <span class="whitespace-nowrap truncate text-sm">{{
                  user?.agency?.name
                }}</span>
              </div>
            </div>
          </HButton>

          <HMenuButton
            icon-left="Globe"
            :is-active="$route.fullPath.includes(`/discover`)"
            :is-collasped="isCollasped"
            class="w-full pl-4 pr-2.5 gap-4"
            @click="navigateTo(`/discover`)"
            >Discover</HMenuButton
          >

          <!-- <HMenuButton
            icon-left="HomeAltSlimHoriz"
            :is-collasped="isCollasped"
            class="w-full pl-4 pr-2 transition-all gap-4 duration-300 ease-hub-ease"
            >Dashboard</HMenuButton
          > -->
        </div>

        <!-- <HButton
          variant="plain"
          :icon-right="isCollasped ? 'SidebarExpand' : 'SidebarCollapse'"
          @click="isCollasped = !isCollasped"
        /> -->
      </div>

      <div class="flex flex-col gap-12">
        <!-- Primary Menu -->
        <div
          class="w-full flex-col justify-start items-start gap-3 inline-flex"
        >
          <div
            v-if="!isAgencySpace && !isSupportSpace"
            class="w-full flex-col justify-start items-start gap-2 inline-flex"
          >
            <span class="px-5 text-sm text-ht-text-gray-1 opacity-70"
              >Client</span
            >

            <HClientSwitcher
              :is-collasped="isCollasped"
              class="w-[233px] px-2"
            ></HClientSwitcher>
          </div>

          <div
            v-if="!isAgencySpace && !isSupportSpace && !!clientSlug"
            class="w-full px-2 flex-col justify-start items-start gap-1.5 inline-flex"
          >
            <div class="hidden text-[#F0BF29]"></div>
            <div class="hidden text-[#CC4D4B]"></div>
            <div class="hidden text-[#904FE0]"></div>
            <HMenuButton
              v-for="nav in clientNavs.root"
              :key="nav.iconLeft"
              :icon-left="nav.iconLeft"
              :icon-left-color="nav.iconLeftColor"
              :indicator="nav.indicator"
              :notifications="nav.notifications"
              :is-active="nav.isActive"
              :is-collasped="isCollasped"
              class="w-full pl-4 pr-2.5 gap-4"
              @click="nav.onClick"
              >{{ nav.name }}</HMenuButton
            >

            <HMenuButton
              v-if="clientNavs.settings.length > 0"
              icon-left="Settings"
              :icon-right="isSettingsActive ? 'NavArrowUp' : 'NavArrowDown'"
              :is-collasped="isCollasped"
              class="w-full pl-4 pr-3 gap-4 transition-all duration-300 ease-hub-ease"
              @click="isSettings = !isSettings"
              >Settings</HMenuButton
            >

            <TransitionGroup name="slide-fade--y">
              <div
                v-if="isSettingsActive"
                class="w-full flex-col justify-start items-start gap-1 inline-flex"
              >
                <HMenuButton
                  v-for="nav in clientNavs.settings"
                  :key="nav.path"
                  :is-collasped="isCollasped"
                  class="w-full pl-[54px] pr-1"
                  :is-active="$route.path === nav.path"
                  @click="navigateTo(nav.path)"
                  >{{ nav.label }}</HMenuButton
                >
              </div>
            </TransitionGroup>
          </div>

          <div
            v-else-if="isAgencySpace"
            class="w-full px-2 flex-col justify-start items-start gap-1 inline-flex"
          >
            <div
              class="w-full mb-10 flex-col justify-start items-start gap-1 inline-flex"
            >
              <div
                class="w-[218px] h-[17px] px-4 justify-start items-center gap-2 inline-flex mb-4"
              >
                <HIcon icon="Building" class="w-4 h-4" />
                <span
                  class="text-ht-hub-text-1-80 text-sm font-medium font-['Inter'] leading-[16.80px]"
                >
                  General
                </span>
              </div>

              <HMenuButton
                v-for="nav in agencyNavs"
                :key="nav.path"
                variant="plain"
                class="w-full px-10 justify-start"
                :is-active="$route.fullPath === nav.path"
                @click="navigateTo(nav.path)"
              >
                {{ nav.name }}</HMenuButton
              >
            </div>

            <!-- TODO: Uncomment when user's settings are ready -->
            <!-- <div
              class="w-full flex-col justify-start items-start gap-1 inline-flex"
            >
              <div
                class="w-[218px] h-[17px] px-4 justify-start items-center gap-2 inline-flex mb-4"
              >
                <HIcon icon="User" class="w-4 h-4" />
                <span
                  class="text-ht-hub-text-1-80 text-sm font-medium font-['Inter'] leading-[16.80px]"
                >
                  User
                </span>
              </div>

              <HMenuButton variant="plain" class="w-full px-10 justify-start">
                Profile</HMenuButton
              >
              <HMenuButton variant="plain" class="w-full px-10 justify-start">
                Notifications</HMenuButton
              >
              <HMenuButton variant="plain" class="w-full px-10 justify-start">
                Security</HMenuButton
              >
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Buttom Section -->
    <div class="px-2 flex flex-col gap-8">
      <div class="flex flex-col gap-2 items-center">
        <div
          class="w-full flex-col justify-start items-start gap-1 inline-flex"
        >
          <!-- TODO: uncomment when ready -->
          <!-- <HMenuButton
            icon-left="Search"
            :indicator="isCollasped ? '' : '⌘'"
            :indicator2="isCollasped ? '' : 'k'"
            :is-collasped="isCollasped"
            class="w-full pl-4 pr-2.5 gap-4"
            >Search</HMenuButton
          >
          <HMenuButton
            icon-left="Bell"
            :indicator="isCollasped ? '' : '0'"
            :is-collasped="isCollasped"
            class="w-full pl-4 pr-2.5 gap-4"
            >Notifications</HMenuButton
          > -->

          <HMenuButton
            icon-left="ChatLines"
            :is-collasped="isCollasped"
            class="w-full pl-4 pr-2.5 gap-4"
            @click="
              navigateTo('https://hyptt.com/feedback', {
                external: true,
                open: { target: '_blank' },
              })
            "
            >Give feedback</HMenuButton
          >

          <HMenuButton
            v-if="useAccess(user, 'SU_GENERAL', 'V')"
            icon-left="HeadsetHelp"
            :is-collasped="isCollasped"
            class="w-full pl-4 pr-2.5 gap-4"
            @click="navigateTo('/support')"
            >hyptT Support</HMenuButton
          >
          <template v-if="user">
            <HDropdown :type="2" align="right-end" width="215px" :offset="-30">
              <HButton
                variant="plain"
                class="w-full min-w-[233px] pl-2.5 pr-3 h-fit rounded-xl hover:bg-ht-menu-btn-hover"
              >
                <div class="w-full inline-flex items-center justify-between">
                  <div class="inline-flex gap-2.5 items-center">
                    <HAvatar
                      :user="user"
                      class="text-ht-text-gray-1 text-xs font-semibold leading-[1]"
                    />
                    <span
                      class="whitespace-nowrap truncate font-medium text-sm text-ht-text-gray-1"
                      >{{ useUserName(user) }}</span
                    >
                  </div>

                  <HIcon
                    icon="NavArrowRight"
                    class="w-[18px] h-[18px] text-ht-text-gray-1"
                  />
                </div>
              </HButton>

              <template #content="{ close }">
                <div
                  :class="{
                    'w-[320px] p-2 bg-ht-primary-bg-fill shadow  flex-col justify-start items-start gap-1.5 inline-flex drop-shadow-hub-dropdown-light dark:drop-shadow-hub-dropdown-dark':
                      !mdAndSmaller,
                    'w-full px-0 pb-4 space-y-2': mdAndSmaller,
                  }"
                >
                  <div
                    class="w-full h-[60px] px-3.5 py-3 bg-ht-cc-rc-bg-fill rounded-[14px] border border-ht-border-1 justify-start items-center gap-2 inline-flex cursor-pointer transition-all duration-300 ease-hub-ease hover:bg-ht-cc-rc-bg-hover"
                    @click="close"
                  >
                    <div class="justify-start items-center gap-3.5 flex">
                      <HAvatar :user="user" :size="9" />
                      <span
                        class="text-ht-monochrome-bw text-sm font-medium font-['Inter'] leading-[16.80px]"
                      >
                        {{ useUserName(user) }}
                      </span>
                    </div>
                  </div>

                  <HModeSwitcher class="w-full" />

                  <HButton
                    v-if="!isAgencySpace && user.agencyId !== null"
                    icon-left="Building"
                    variant="plain"
                    class="w-full pl-3 justify-start"
                    @click="navigateTo('/agency')"
                    >Agency Settings</HButton
                  >
                  <HButton
                    icon-left="LogOut"
                    variant="plain"
                    class="w-full pl-3 justify-start"
                    @click="signOut"
                    >Log out</HButton
                  >
                </div>
              </template>
            </HDropdown>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.primary-sidebar {
  @apply transition-all duration-300 ease-hub-ease;
  @apply w-[250px] py-4 bg-ht-primary-bg-fill-90 border-r border-ht-border-1 flex flex-col justify-between;

  &.collasped {
    @apply w-[70px] px-2 items-center;
  }
}

.cc-thumb {
  @apply w-7 h-7 bg-ht-btn-gray rounded-lg border border-ht-border-2 flex-col justify-center items-center gap-2.5 inline-flex;
  @apply text-ht-text-gray-1 text-xs font-semibold leading-[1];

  &.full-rounded {
    @apply rounded-full;
  }
}
</style>
