<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    isCollasped?: boolean;
  }>(),
  {
    isCollasped: false,
  }
);
</script>

<template>
  <div :class="{ 'mode-switcher ': true, collasped: props.isCollasped }">
    <HButton
      :variant="$colorMode.preference === 'light' ? 'primary' : 'plain'"
      icon-left="SunLight"
      :class="{
        'w-full border-none': true,
        'p-2': props.isCollasped,
        'bg-ht-monochrome-bw/[0.08]': $colorMode.preference === 'light',
        'text-gray-600': $colorMode.preference === 'light',
      }"
      @click="$colorMode.preference = 'light'"
    />
    <HButton
      v-if="!props.isCollasped"
      :variant="$colorMode.preference === 'dark' ? 'primary' : 'plain'"
      icon-left="HalfMoon"
      :class="{
        'w-full': true,
        'border-none': true,
        'bg-ht-monochrome-bw/[0.15]': $colorMode.preference === 'dark',
        'text-purple-50': $colorMode.preference === 'dark',
      }"
      @click="$colorMode.preference = 'dark'"
    />
    <HButton
      v-if="!props.isCollasped"
      :variant="$colorMode.preference === 'system' ? 'primary' : 'plain'"
      icon-left="Computer"
      :class="{
        'w-full': true,
        'border-none': true,
        'bg-ht-monochrome-bw/[0.15]':
          $colorMode.value === 'dark' && $colorMode.preference === 'system',
        'bg-ht-monochrome-bw/[0.08]':
          $colorMode.value === 'light' && $colorMode.preference === 'system',
        'text-gray-600':
          $colorMode.value === 'light' && $colorMode.preference === 'system',
        'text-purple-50':
          $colorMode.value === 'dark' && $colorMode.preference === 'system',
      }"
      @click="$colorMode.preference = 'system'"
    />
  </div>
</template>

<style lang="scss" scoped>
.mode-switcher {
  @apply overflow-hidden transition-all duration-300 ease-hub-ease;
  @apply w-[218px] p-1 bg-ht-cc-rc-bg-fill rounded-[14px] justify-center items-start gap-2.5 inline-flex;

  &.collasped {
    @apply items-center w-full rounded-xl;
  }
}
</style>
