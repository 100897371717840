<script setup lang="ts">
import type * as icons from '@/assets/icons';
const props = withDefaults(
  defineProps<{
    iconLeft?: keyof typeof icons | '';
    iconLeftColor?: string;
    iconRight?: keyof typeof icons | '';
    isActive?: boolean;
    indicator?: string;
    indicator2?: string;
    notifications?: number;
    isCollasped?: boolean;
  }>(),
  {
    iconLeft: '',
    iconLeftColor: '',
    iconRight: '',
    isActive: false,
    indicator: '',
    indicator2: '',
    notifications: 0,
    isCollasped: false,
  }
);
</script>

<template>
  <div
    :class="{
      'menu-btn': true,
      active: props.isActive,
      'left-icon-only': props.isCollasped,
    }"
  >
    <slot name="icon-left">
      <HIcon
        v-if="props.iconLeft"
        :icon="props.iconLeft"
        :class="`w-[18px] h-[18px] text-[${props.iconLeftColor}]`"
      ></HIcon>
    </slot>

    <span v-if="!props.isCollasped" class="grow">
      <slot />
    </span>

    <div
      v-if="props.indicator || props.indicator2 || !props.isCollasped"
      class="justify-start items-center flex gap-1"
    >
      <HIndicator v-if="props.indicator">{{ props.indicator }}</HIndicator>
      <HIndicator v-if="props.indicator2">{{ props.indicator2 }}</HIndicator>
      <HIndicator v-if="props.notifications">
        <div class="inline-flex items-center">
          <HIcon icon="ChatLines" class="w-3 h-3 mr-1"></HIcon>
          <span>{{ props.notifications }}</span>
        </div>
      </HIndicator>
    </div>

    <slot v-if="!props.isCollasped" name="icon-right">
      <HIcon
        v-if="props.iconRight"
        :icon="props.iconRight"
        class="w-[18px] h-[18px]"
      ></HIcon>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.menu-btn {
  @apply cursor-pointer overflow-hidden transition-all duration-300 ease-hub-ease;
  @apply w-full max-w-[238px] h-9 pl-4 pr-3 py-2.5 rounded-lg justify-start items-center gap-2 inline-flex;
  @apply text-ht-text-gray-1 text-sm font-medium leading-[1.2];

  &:hover {
    @apply bg-ht-menu-btn-hover;
  }

  &.active {
    @apply bg-ht-menu-btn-active;
  }

  &.left-icon-only {
    @apply w-10 px-2 justify-center;
  }
}
</style>
