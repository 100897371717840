<script setup lang="ts">
import HyptTLogo from '~/assets/svg/hyptt.svg';

const modalOpen = ref<boolean>(false);

const route = useRoute();

watch(
  () => route.fullPath,
  () => {
    modalOpen.value = false;
  }
);
</script>

<template>
  <div
    class="hidden w-full h-[60px] px-4 py-[13px] bg-ht-primary-bg-fill-90 border-b border-ht-border-1 justify-between items-center md:flex"
  >
    <div class="justify-start items-center gap-2 inline-flex">
      <!-- <HIcon icon="Menu" class="w-5 h-5" /> -->

      <HButton
        variant="plain"
        icon-left="Menu"
        class="w-[30px] h-[30px] px-1"
        @click="modalOpen = !modalOpen"
      />

      <HSlideOver
        v-model="modalOpen"
        title="Menu"
        :show-header="false"
        :show-controls="false"
        side="left"
        width="max-w-fit md:max-w-fit"
      >
        <div class="h-screen flex flex-col justify-start items-start">
          <HPrimarySidebar :is-mobile="true" class="h-screen" />
        </div>
      </HSlideOver>
      <HyptTLogo class="fill-ht-hub-text-1 w-[62px]" />
    </div>

    <div class="justify-start items-start gap-0.5 inline-flex">
      <!-- <HButton icon-right="Search" variant="plain" />
      <HButton icon-right="Bell" variant="plain" /> -->
      <!-- <HClientSwitcher
        :is-collasped="false"
        :is-mobile-header="true"
      ></HClientSwitcher> -->
    </div>
  </div>
</template>
